import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Success Story | Stogies Jazz Club & Listening Room",
    pageDescription:
      "Describe how the business uses SpotOn to improve its revenue and operations.",
    title: "A jazz and cigar bar makes its operations smoother",
    subTitle: `How Stogies Jazz Club & Listening Room increases efficiency and revenue with SpotOn.`,
    businessName: "Stogies Jazz Club & Listening Room",
    industry: "Restaurant & hospitality, bars & breweries",
    location: "St. Augustine, Florida",
    products: [spotonProducts.RESTAURANT,],
    description:
      "Locally owned and operated by Jeff Holleran for more than 25 years, Stogies is the premier cigar bar and jazz listening room in the historic district of St. Augustine, Florida. And with SpotOn as a tech partner, Holleran has seen a dramatic boost in staff performance and sales at his popular nightlife spot.",
    quoteData: {
      imgName: "stogies-jazz-club.png",
      quote: `“SpotOn’s software is quick and efficient, and the customer service is great. The attention to detail they've put into their products is the best I've seen, bar none.”`,
      personName: "Jeff Holleran",
      personTitle: "Owner, Stogies Jazz Club & Listening Room",
    },
    goal: `To streamline operations and retain his best staff, Holleran needed a POS system that would be easy for everyone on his team to use. And to reduce expenses, he also wanted to eliminate the support fees required by his former POS provider.`,
    solution: `With SpotOn, Stogies serves more guests per hour thanks to a user-friendly restaurant POS system that servers love. Also, with SpotOn’s 24/7/365 support, Holleran can get ongoing assistance with his POS system at no extra cost.`,
    results: {
      title: `The results`,
      stats: [
        { title: `20%`, description: `increase in revenue` },
        {
          title: `25-30%`,
          description: `increase in servers' tips`,
        },
        { title: `$300`, description: `monthly savings with complimentary support` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Loyalty that's right on target",
        imageName: "smashin-good-time.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-02",
        href: "/case-studies/smashin-good-time",
        goals: [
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
        title: "Beer, beef, and baseball",
        imageName: "von-elrods-beer-hall-and-kitchen.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-02-21",
        href: "/case-studies/von-elrods-beer-hall-and-kitchen",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Boosting traffic to a BBQ business",
        imageName: "chicago-culinary-kitchen.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-02",
        href: "/case-studies/chicago-culinary-kitchen",
        goals: [
          resourceGoals.REACH_NEW_CUSTOMERS,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  